import { getCurrentBreakpoint } from '@/src/utils/tailwindHelper'
import throttle from 'lodash/throttle'
import { useEffect, useState } from 'react'

function useBreakpoint() {
  const [breakpoint, setBreakpoint] = useState('')
  useEffect(() => {
    const handleResize = throttle(() => {
      setBreakpoint(getCurrentBreakpoint())
    }, 200)
    window.addEventListener('resize', handleResize)
    handleResize()
    return () => window.removeEventListener('resize', handleResize)
  }, [])
  return breakpoint
}

export default useBreakpoint
