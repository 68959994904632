import ImageLang from '@/src/components/ImageLang'
import LocalizedLink from '@/src/components/LocalizedLink'
import classes from '@/src/components/Thumbnail/game.module.scss'
import { PositioningProviderType } from '@/src/services/types/games'
import { generateProviderImagePath } from '@/src/utils/image'

type ProviderProps = {
  provider: PositioningProviderType
  href: string
}

const ProviderThumbnail = ({ provider, href }: ProviderProps) => {
  const src = generateProviderImagePath(provider.providerId)
  return (
    <div
      className={`${classes.wrapperSlide} ${classes.provider} flex aspect-[4/3] justify-center align-middle`}
    >
      <LocalizedLink href={href} className='flex items-center p-8'>
        <ImageLang
          src={src}
          width={150}
          alt={provider.name}
          height={50}
          sizes='100vw'
          fetchPriority='low'
          style={{
            width: '100%',
            height: 'auto',
            objectFit: 'contain',
          }}
        />
      </LocalizedLink>
    </div>
  )
}

ProviderThumbnail.displayName = 'ProviderThumbnail'

export default ProviderThumbnail
