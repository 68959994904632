import LocalizedLink from '@/src/components/LocalizedLink'
import { GameThumbnail } from '@/src/components/Thumbnail'
import { GameType } from '@/src/services/types'
import { PositioningProvidersType } from '@/src/services/types/games'
import { JackpotType } from '@/src/services/types/jackpot'
import { getGamePath } from '@/src/utils/helpers/gameHelper'

export const filterFavourites = (
  games: GameType[] | undefined,
  providers: PositioningProvidersType,
  jackpots: JackpotType[] | undefined,
  isFavorite: (game: GameType) => boolean,
  isLoggedIn: boolean | undefined,
) => {
  return games
  ?.filter((game) =>
    providers?.find((provider) => provider.providerId === game.provider),
  )
  .map((game) => (
    <LocalizedLink href={getGamePath(game, providers)} key={game.gameId}>
      <GameThumbnail
        slug={game.friendlyName}
        game={game}
        showName={!!isLoggedIn}
        favourite={isFavorite(game)}
        jackpots={jackpots}
        thumbnailGameName={game.name}
      />
    </LocalizedLink>
  ));
}
