import { isMobile as _isMobile } from '@/src/utils/helpers/browserHelpers'
import { useEffect, useState } from 'react'

export function useIsMobile() {
  const [isMobile, setIsMobile] = useState(false)

  useEffect(() => {
    setIsMobile(_isMobile())
  }, [])

  return isMobile
}
