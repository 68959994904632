import I18N from '@/src/app/_i18n/i18nConfig.ts'
import { TranslationsArr } from 'i18next'

export const fallbackLng = I18N.i18n.defaultLocale
export const languages = I18N.i18n.locales
export const defaultNS = 'common'
export type SupportedLangsType = (typeof languages)[number]

export interface LangParamsType {
  params: Promise<{
    lang: SupportedLangsType
  }>
}
// Utility type to combine a base type with LangParamsType
export type WithLangParams<T> = { params: Promise<T> } & LangParamsType

export function getOptions(
  lng: SupportedLangsType = fallbackLng,
  ns: TranslationsArr | string = defaultNS,
) {
  return {
    // debug: true,
    supportedLngs: languages,
    load: 'currentOnly' as const,
    fallbackLng,
    lng,
    fallbackNS: defaultNS,
    defaultNS,
    ns,
  }
}
