import(/* webpackMode: "eager" */ "/home/runner/work/lucky-sweden/lucky-sweden/node_modules/@next/third-parties/dist/google/ga.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/lucky-sweden/lucky-sweden/node_modules/@next/third-parties/dist/google/gtm.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/lucky-sweden/lucky-sweden/node_modules/@next/third-parties/dist/ThirdPartyScriptEmbed.js");
;
import(/* webpackMode: "eager", webpackExports: ["PrismicToolbar"] */ "/home/runner/work/lucky-sweden/lucky-sweden/node_modules/@prismicio/react/dist/PrismicToolbar.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/lucky-sweden/lucky-sweden/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/lucky-sweden/lucky-sweden/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/lucky-sweden/lucky-sweden/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/lucky-sweden/lucky-sweden/node_modules/next/dist/shared/lib/lazy-dynamic/preload-chunks.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/lucky-sweden/lucky-sweden/src/app/_i18n/provider.tsx");
;
import(/* webpackMode: "eager" */ "/home/runner/work/lucky-sweden/lucky-sweden/src/app/[lang]/(lobby)/(casino)/LobbyClient.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/lucky-sweden/lucky-sweden/src/components/Accordion/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/lucky-sweden/lucky-sweden/src/components/AuthButton/AuthButtonClient.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/lucky-sweden/lucky-sweden/src/components/BackButton/BackButtonClient.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/lucky-sweden/lucky-sweden/src/components/Bonus/BonusCard/BonusVideo.tsx");
;
import(/* webpackMode: "eager" */ "/home/runner/work/lucky-sweden/lucky-sweden/src/components/Button/RoundedButton/index.tsx");
;
import(/* webpackMode: "eager" */ "/home/runner/work/lucky-sweden/lucky-sweden/src/components/Layout/Footer/Footer.module.scss");
;
import(/* webpackMode: "eager" */ "/home/runner/work/lucky-sweden/lucky-sweden/src/components/Layout/TopBar/TopBar.tsx");
;
import(/* webpackMode: "eager" */ "/home/runner/work/lucky-sweden/lucky-sweden/src/components/LocalizedLink/index.tsx");
;
import(/* webpackMode: "eager" */ "/home/runner/work/lucky-sweden/lucky-sweden/src/components/PageTitle/PageTitle.module.scss");
;
import(/* webpackMode: "eager" */ "/home/runner/work/lucky-sweden/lucky-sweden/src/components/Layout/SliceZone/SliceZone.module.scss");
;
import(/* webpackMode: "eager" */ "/home/runner/work/lucky-sweden/lucky-sweden/src/components/Button/FeatureButton/FeatureButton.module.scss");
;
import(/* webpackMode: "eager" */ "/home/runner/work/lucky-sweden/lucky-sweden/src/components/Button/FlatButton/FlatButton.module.scss");
;
import(/* webpackMode: "eager" */ "/home/runner/work/lucky-sweden/lucky-sweden/src/components/Button/ListButton/ListButton.module.scss");
;
import(/* webpackMode: "eager" */ "/home/runner/work/lucky-sweden/lucky-sweden/src/components/NoYMargin/NoYmargin.module.scss");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/lucky-sweden/lucky-sweden/src/components/ShowFinishOnbaording/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/lucky-sweden/lucky-sweden/src/components/ShowLoadingModal/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/lucky-sweden/lucky-sweden/src/components/ShowLoginInfo/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SimpleSliderFull"] */ "/home/runner/work/lucky-sweden/lucky-sweden/src/components/SimpleSlider/SimpleSlider.tsx");
;
import(/* webpackMode: "eager" */ "/home/runner/work/lucky-sweden/lucky-sweden/src/widgets/IntercomWidget.tsx");
