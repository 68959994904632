'use client'
import { initI18nextClient } from '@/src/app/_i18n/init'
import { SupportedLangsType } from '@/src/app/_i18n/settings.tsx'
import {
  ResourceLanguage,
  TranslationsArr,
  TranslationsType,
  createInstance,
} from 'i18next'
import { ReactNode } from 'react'
import { I18nextProvider } from 'react-i18next'

type TranslationsProviderProps = {
  children: ReactNode
  namespaces: TranslationsArr | TranslationsType
  resources: ResourceLanguage
  lang: SupportedLangsType
}
export default function TranslationsProvider({
  children,
  namespaces,
  resources,
  lang,
}: TranslationsProviderProps) {
  const i18n = createInstance()

  initI18nextClient(lang, namespaces, i18n, resources)

  return <I18nextProvider i18n={i18n}>{children}</I18nextProvider>
}
