import { GameType } from '@/src/services/types'
import LocalizedLink from '@/src/components/LocalizedLink'
import { GameThumbnail } from '@/src/components/Thumbnail'
import { FamilyType, PositioningProvidersType } from '@/src/services/types/games'
import { JackpotType } from '@/src/services/types/jackpot'
import { getGamePath } from '@/src/utils/helpers/gameHelper'

export const filterRecents = (
  families: FamilyType[] | undefined,
  providers: PositioningProvidersType,
  jackpots: JackpotType[] | undefined,
  isFavorite: (game: GameType) => boolean,
  isLoggedIn: boolean | undefined,
  isMobile: boolean
) => {
  return families
  ?.filter((family) =>
    providers?.find((provider) => provider.providerId === family.provider),
  )
  .map((family) => {
    const game = family.games.find((game) => game.mobile === isMobile)

    if (!game) return

    return(
      <LocalizedLink href={getGamePath(game, providers)} key={game.gameId}>
        <GameThumbnail
          slug={family.familySlug}
          displayName={family.familyName}
          game={game}
          showName={!!isLoggedIn}
          favourite={isFavorite(game)}
          jackpots={jackpots}
          thumbnailGameName={game.name}
        />
      </LocalizedLink>
    )
  }
  ).filter((el) =>  el !== undefined)
}