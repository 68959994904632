'use client'
import { RoundedButton } from '@/src/components/Button'
import LocalizedLink from '@/src/components/LocalizedLink'
import useAuthGuard from '@/src/hooks/useAuthGuard'
import { TranslationsType } from 'i18next'
import { useTranslation } from 'react-i18next'

export type AuthButtonProps = {
  loggedInPath?: string
  loggedOutpath?: string
  buttonTxt?: string
  transdoc?: TranslationsType
}

const AuthButtonClient = (props: AuthButtonProps) => {
  const { isLoggedIn } = useAuthGuard()
  const { loggedInPath, loggedOutpath, buttonTxt, transdoc } = props
  const { t } = useTranslation([transdoc])
  return (
    <LocalizedLink href={`${isLoggedIn ? loggedInPath : loggedOutpath}`}>
      <RoundedButton type='primary' className='animate-glow'>
        {buttonTxt && t(buttonTxt, buttonTxt)}
      </RoundedButton>
    </LocalizedLink>
  )
}

export default AuthButtonClient
