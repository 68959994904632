import { getOptions, SupportedLangsType } from '@/src/app/_i18n/settings.tsx'
import {
  createInstance,
  i18n,
  ResourceLanguage,
  TranslationsArr,
  TranslationsType,
} from 'i18next'
import { initReactI18next } from 'react-i18next/initReactI18next'

// To be used on client components since we don't have access to the server's resources
export const initI18nextClient = async (
  lng: SupportedLangsType,
  ns: TranslationsArr | TranslationsType,
  i18nInstance?: i18n,
  resources?: ResourceLanguage,
) => {
  i18nInstance = i18nInstance || createInstance()

  const objInit = {
    ...getOptions(lng, ns),
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  }

  if (resources) {
    //@ts-ignore
    objInit.resources = resources
  }

  await i18nInstance.use(initReactI18next).init(objInit)
  return i18nInstance
}
