'use client'
import SimpleSlider from '@/src/components/SimpleSlider/SimpleSlider'
import ProviderThumbnail from '@/src/components/Thumbnail/ProviderThumbnail/ProviderThumbnail'
import { ALTENAR_PROVIDER_ID } from '@/src/const/providers.ts'
import useAuthGuard from '@/src/hooks/useAuthGuard'
import {
  useGetFavouriteGamesQuery,
  useGetRecentGamesQuery,
} from '@/src/services/games'
import { useGetJackpotsQuery } from '@/src/services/jackpots'
import { GameType, PositioningGroupType } from '@/src/services/types'
import { FamilyType, PositioningProvidersType } from '@/src/services/types/games'
import { buildGameThumbnails } from '@/src/utils/helpers/thumbnails'
import { slugify } from '@/src/utils/slugHelper'
import { Fragment, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useIsMobile } from '@/src/hooks/useIsMobile'
import { filterFavourites } from '@/src/app/[lang]/(lobby)/(casino)/utils/filterFavourites'
import { filterRecents } from '@/src/app/[lang]/(lobby)/(casino)/utils/filterRecents'

type LobbyProps = {
  groupGames: PositioningGroupType[]
  providers: PositioningProvidersType
  children?: React.ReactNode
}

const RECENT_GAMES_ROW = 3
const FAVOURITE_GAMES_ROW = 3

export default function LobbyClient({
  groupGames,
  providers,
  children,
}: LobbyProps) {
  const { t } = useTranslation(['family-groups', 'meta'])
  const { isLoggedIn } = useAuthGuard()

  const isMobile = useIsMobile()

  const { data: jackpots } = useGetJackpotsQuery()
  const { data: recentGames, isLoading: recentGamesLoading } =
    useGetRecentGamesQuery(undefined, {
      skip: !isLoggedIn,
    })
  const { data: favouriteGames, isLoading: favouriteGamesLoading } =
    useGetFavouriteGamesQuery(undefined, {
      skip: !isLoggedIn,
    })

  const isFavorite = useCallback(
    (game: GameType) => {
      return !!favouriteGames?.games.find((g) => g.gameId === game.gameId)
    },
    [favouriteGames],
  )

  const filteredItemsFavourites = useCallback(
    (games: GameType[] | undefined) =>
      filterFavourites(
        games,
        providers,
        jackpots,
        isFavorite,
        isLoggedIn
      ),
    [providers, jackpots, isFavorite, isLoggedIn]
  )

  const filteredItemsRecent = useCallback(
    (families: FamilyType[] | undefined) =>
      filterRecents(
        families,
        providers,
        jackpots,
        isFavorite,
        isLoggedIn,
        isMobile
      ),
    [jackpots, providers, isFavorite, isLoggedIn, isMobile],
  )

  const recentGamesList = useCallback(
    (games?: FamilyType[] | undefined) => {
      if (!recentGames || recentGames.length === 0) {
        return null
      }

      const filterItems = filteredItemsRecent(games)

      return (
        <SimpleSlider
          showSkeleton={recentGamesLoading}
          title={t('recently-played')}
          items={filterItems || []}
          type={'recently-played'}
          slug={'recently-played'}
        />
      )
    },
    [recentGames, recentGamesLoading, filteredItemsRecent, t],
  )

  const favouriteGamesList = useCallback(
    (games?: GameType[] | undefined) => {
      if (!favouriteGames || favouriteGames.games.length === 0) {
        return null
      }

      return (
        <SimpleSlider
          showSkeleton={favouriteGamesLoading}
          title={t('favourite-games')}
          items={filteredItemsFavourites(games) || []}
          type={'favourite-games'}
          slug={'favourite-games'}
        />
      )
    },
    [favouriteGames, favouriteGamesLoading, filteredItemsFavourites, t],
  )

  const categories = useMemo(
    () =>
      groupGames
        ?.filter((groups) => groups.families.length !== 0)
        ?.map((group, index) => {
          return (
            <Fragment key={`fragment-key-${group.name}`}>
              {index + 1 === RECENT_GAMES_ROW &&
                recentGamesList(recentGames)}
              {index + 1 === FAVOURITE_GAMES_ROW &&
                favouriteGamesList(favouriteGames?.games)}
              <SimpleSlider
                slug={group.slug}
                title={t(group.slug, { defaultValue: group.slug })}
                items={buildGameThumbnails(
                  group.families,
                  !!isLoggedIn,
                  favouriteGames?.games ?? [],
                  jackpots,
                  group.slug,
                  false,
                )}
                type='games'
              />
            </Fragment>
          )
        }),
    [
      groupGames,
      recentGamesList,
      recentGames,
      favouriteGamesList,
      favouriteGames?.games,
      t,
      jackpots,
      isLoggedIn,
    ],
  )

  const providerItems = (providers: PositioningProvidersType) => {
    return (
      providers
        // Filter Altenar provider
        ?.filter((v) => v.providerId !== ALTENAR_PROVIDER_ID)
        .map((provider) => (
          <ProviderThumbnail
            key={provider.providerId}
            provider={provider}
            href={`/providers/${slugify(provider.name)}`}
          />
        ))
    )
  }

  const providersSlide = useMemo(
    () => (
      <div className='col-span-12'>
        <SimpleSlider
          title={t('providers')}
          items={providerItems(providers)}
          type='providers'
        />
      </div>
    ),
    [providers, t],
  )

  return (
    <>
      <div className='relative mx-auto max-w-7xl bg-primary'>
        {categories}
        {providers && providersSlide}
      </div>
      {!isLoggedIn && children}
    </>
  )
}
