'use client'

import useBreakpoint from '@/src/hooks/useBreakpoint'
import { isPlaying } from '@/src/utils/helpers/videoPlayHelper'
import { captureException } from '@sentry/nextjs'
import { useEffect, useRef } from 'react'

const BonusVideo = () => {
  const videoRef = useRef<HTMLVideoElement>(null)
  const breakpoint = useBreakpoint()

  useEffect(() => {
    const videoPlayer = videoRef.current
    if (videoPlayer && !isPlaying(videoPlayer) && breakpoint != '') {
      const playPromise = videoPlayer.play()
      if (playPromise !== undefined)
        playPromise.catch((e) => captureException(e))
    }
  }, [breakpoint])

  const casinoVideo =
    breakpoint < '420' || breakpoint === ''
      ? 'images/bonus/WO-size-mobile.mp4'
      : 'images/bonus/WO-size-desktop.mp4'

  return (
    <video
      className='absolute inset-0 h-full w-full object-cover object-center'
      ref={videoRef}
      src={casinoVideo}
      muted
      loop
      playsInline
    />
  )
}

export default BonusVideo
