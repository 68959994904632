import { api } from '@/src/services'
import { PlayerReportSummaryType } from '@/src/services/types/PlayerReportSummary.tsx'

const reportApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getPlayerReportSummary: builder.query<PlayerReportSummaryType, void>({
      query: () => '/report/playerReportSummary',
    }),
  }),
  overrideExisting: process.env.NODE_ENV === 'development',
})

export const { useGetPlayerReportSummaryQuery } = reportApi

//SSR endpoints
export const { getPlayerReportSummary } = reportApi.endpoints
export default reportApi
