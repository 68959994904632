const tailwindConfig = {
  theme: {
    screens: {
      xxs: '1px',
      xs: '512px',
      '3xl': '1900px',
      '4xl': '2200px',
      '69px': '69px',
      '100px': '100px',
      '120px': '120px',
      '162px': '162px',
      tall: { raw: '(min-height: 800px)' },
      narrow: { raw: '(max-height: 500px)' },
      normalPhone: { raw: '(max-width: 430px) and ( max-height: 1100px )' },
      smallPhone: { raw: '(max-width: 385px ) and ( max-height: 680px)' },
    },
  },
}

export const getBreakpointValue = (
  value: keyof typeof tailwindConfig.theme.screens,
): number => {
  const screenValue = tailwindConfig.theme.screens[value]

  if (typeof screenValue === 'string') {
    return parseInt(screenValue.replace('px', ''), 10)
  } else if (typeof screenValue === 'object' && 'raw' in screenValue) {
    // Extract the numeric value from the 'raw' property
    const rawValue = screenValue.raw.match(/\d+/)
    if (rawValue) {
      return parseInt(rawValue[0], 10)
    }
  }

  return 0 // Default value when unable to determine the breakpoint
}

export const getCurrentBreakpoint = (): string => {
  if (typeof window == 'undefined') return ''
  let currentBreakpoint = 'sm'
  let biggestBreakpointValue = 0
  for (const breakpoint of Object.keys(tailwindConfig.theme.screens)) {
    const breakpointValue = getBreakpointValue(
      breakpoint as keyof typeof tailwindConfig.theme.screens,
    )
    if (
      breakpointValue > biggestBreakpointValue &&
      window.innerWidth >= breakpointValue
    ) {
      biggestBreakpointValue = breakpointValue
      currentBreakpoint = breakpoint
    }
  }
  // When we don't get a value means we are on the smallest
  return currentBreakpoint
}
