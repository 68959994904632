'use client'
import { RoundedButton } from '@/src/components/Button'
import LocalizedLink from '@/src/components/LocalizedLink'
import Modal from '@/src/components/Modal/Modal'
import Paragraph from '@/src/components/Typography/Paragraph'
import useAuthGuard from '@/src/hooks/useAuthGuard'
import useDateFormatter from '@/src/hooks/useDateFormatter'
import { api } from '@/src/services'
import {
  useGetLimitsQuery,
  useGetPaymentActivityQuery,
  useGetPlayerQuery,
} from '@/src/services/player'
import { useGetPlayerReportSummaryQuery } from '@/src/services/report'
import { closeModal, showModal } from '@/src/store/slices/modalSlice'
import {
  clearInfoModal,
  getInfoModal,
  setInfoModal,
} from '@/src/utils/localStorage'
import timeFormatter from '@/src/utils/services/timeFormatter'
import { usePathname } from 'next/navigation'
import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { regHelper } from '@/src/components/Steps/utils/regHelper'

const CheckLoginInfo = () => {
  const { t } = useTranslation(['common'])
  const dispatch = useDispatch()
  const { isLoggedIn } = useAuthGuard()
  const [lastLoginTime, setLastLoginTime] = useState<string | undefined>(
    undefined,
  )
  const { data: userDetails } = useGetPlayerQuery(undefined)

  const pathname = usePathname()

  const pageToOpen = useCallback(() => {
    const regex = new RegExp(
      '/missing-info|/login|/logged-out|/logout|/bonus|/my-account/edit/email|/responsible-gambling|/privacy-statement|/register|/pnp/swish|/pnp/swish/success|/pnp/swish/error|/pnp/deposit/success|/pnp',
      'g',
    )
    return !regex.test(pathname || '')
  }, [pathname])

  const { data: limits } = useGetLimitsQuery()
  const { data: playerReportSummary } = useGetPlayerReportSummaryQuery()
  const { data: payment } = useGetPaymentActivityQuery(undefined, {
    skip: !isLoggedIn,
  })
  const { formatter: date } = useDateFormatter({})

  useEffect(() => {
    if (isLoggedIn && userDetails && limits && getInfoModal() !== 'false' && pageToOpen()) {
      const userValidation = regHelper(userDetails, limits)
      if(userValidation.hasDepositLimits && userValidation.hasPhoneOrEmailVerified) {
        dispatch(showModal('login-info-modal'))
        setInfoModal('true')
      }
    }
  }, [dispatch, isLoggedIn, limits, pageToOpen, userDetails])

  useEffect(() => {
    if (isLoggedIn === false) {
      clearInfoModal()
    }
  }, [isLoggedIn])

  useEffect(() => {
    if (!isLoggedIn) {
      setLastLoginTime(undefined)
      dispatch(api.util.invalidateTags(['PaymentActivity']))
      return
    }

    const loginActivitites = payment?.activities?.filter(
      (activity) => activity.activityCode === 'Login',
    )

    if (!loginActivitites || !loginActivitites[1]) {
      return
    }

    const dateLogin = new Date(loginActivitites[1].createdTime)
    setLastLoginTime(`${date.format(dateLogin)} ${timeFormatter(dateLogin)}`)
  }, [date, payment, isLoggedIn, dispatch])

  const cashSumCurrency =
    playerReportSummary &&
    playerReportSummary?.transactions &&
    playerReportSummary.transactions.length > 0
      ? playerReportSummary?.transactions[0].currency
      : ''

  const total =
    playerReportSummary &&
    playerReportSummary?.transactions &&
    playerReportSummary?.transactions.length > 0
      ? playerReportSummary?.transactions[0].cashWin -
        playerReportSummary?.transactions[0].cashBet
      : 0

  const headerKey =
    total > 0 ? 'limits-modal-total-win' : 'limits-modal-total-loss'
  const sum = !total ? '' : `${t(headerKey)} `

  return (
    <Modal
      targetModal='login-info-modal'
      allowClickOutside={true}
      heading={t('limits-modal-header')}
      onClose={() => setInfoModal('false')}
    >
      <div className='mt-5 text-center text-lg normal-case leading-snug opacity-90'>
        <Paragraph className='mb-6'>
          {t('limits-modal-link')}{' '}
          <LocalizedLink
            href='/static/responsible-gambling'
            className='cursor-pointer border-b-2 border-secondary font-medium text-secondary hover:border-secondary-dark hover:text-secondary-dark'
            onClick={() => {
              dispatch(closeModal('login-info-modal'))
              setInfoModal('false')}
            }
          >
            {t('limits-modal-here')}
          </LocalizedLink>
        </Paragraph>
        <div className='mb-12 opacity-90'>
          <p className='mb-6 px-5'>
            {t('limiits-modal-daily-deposit')}{' '}
            <span className='font-bold opacity-100'>
              {limits?.dailyDeposit?.currency} {limits?.dailyDeposit?.amount}
            </span>
          </p>
          <p className='mb-6 px-5'>
            {t('limiits-modal-weekly-deposit')}{' '}
            <span className='font-bold opacity-100'>
              {limits?.weeklyDeposit?.currency} {limits?.weeklyDeposit?.amount}
            </span>
          </p>
          <p className='mb-6 px-5'>
            {t('limiits-modal-monthly-deposit')}{' '}
            <span className='font-bold opacity-100'>
              {limits?.monthlyDeposit?.currency}{' '}
              {limits?.monthlyDeposit?.amount}
            </span>
          </p>
        </div>
        <div>
          <LocalizedLink
            href='/my-account/gambling-limits/deposit'
            className='border-b-2 border-secondary font-medium text-secondary hover:border-secondary-dark hover:text-secondary-dark'
            onClick={() => {
              dispatch(closeModal('login-info-modal'))
              setInfoModal('false')
            }}
          >
            {t('limits-modal-change-deposit-limits')}
          </LocalizedLink>
        </div>
        <div>
          <Paragraph className='mb-6 mt-12'>
            {t('limits-modal-profit-sum')}{' '}
            <span className='font-black opacity-100'>
              <>{`${sum} ${total.toFixed(2)} ${cashSumCurrency}`}</>
            </span>
          </Paragraph>
        </div>
        <div className='mx-auto mb-6 flex w-3/4 flex-col items-center gap-6'>
          <RoundedButton
            className='h-12 w-full'
            type='primary'
            onClick={() => {
              dispatch(closeModal('login-info-modal'))
              setInfoModal('false')
            }}
          >
            {t('limits-modal-continue')}
          </RoundedButton>
        </div>
        <div>
          <Paragraph className='opacity-90'>
            {t('limits-modal-last-login')}{' '}
            <span className='border-b-2 border-white font-bold italic opacity-100'>
              {lastLoginTime}
            </span>
          </Paragraph>
        </div>
      </div>
    </Modal>
  )
}

export default CheckLoginInfo
