'use client'
import styles from '@/src/components/Accordion/Accordion.module.scss'
import classNames from 'classnames'
import { ReactElement, useState } from 'react'
import { FaArrowDown, FaMinusCircle, FaPlusCircle } from 'react-icons/fa'

type Props = {
  items: Array<AccordionItem>
  className?: string
  variant?: string
}

type AccordionItem = {
  headline: string | ReactElement
  panel: string | ReactElement
  action?: ReactElement
  primaryAction?: ReactElement
  variant?: string
}

function Accordion({ items, className, variant }: Props) {
  return (
    <div className={classNames(className, variant)}>
      {items.map((s, i) => (
        <AccordionItem key={i} {...s} variant={variant} />
      ))}
    </div>
  )
}

function AccordionItem({
  headline,
  panel,
  action = <FaArrowDown />,
  primaryAction = <div />,
  variant,
}: AccordionItem) {
  const [expanded, setExpanded] = useState(false)
  let icon = action
  if (variant === 'Unset') {
    icon = !expanded ? <FaPlusCircle /> : <FaMinusCircle />
  }

  return (
    <div
      className={classNames(styles.item, variant && styles[variant], {
        [styles.expanded]: expanded,
      })}
    >
      <div className={styles.headline} onClick={() => setExpanded(!expanded)}>
        {primaryAction}
        <div className={styles.headlineTitle}>{headline}</div>
        <div
          className={classNames(styles.headlineAction, {
            [styles.headlineActionExpanded]: expanded,
          })}
        >
          {icon}
        </div>
      </div>
      {expanded && (
        <div className={styles.panel}>
          <hr className={styles.separator} />
          <div className={styles.panelContent}>{panel}</div>
        </div>
      )}
    </div>
  )
}

export default Accordion
